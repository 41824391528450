<template>
  <div class="page-wrapper">
    <h1 class="title px-3 pt-3 pb-3 pb-sm-0">
      {{ $t('user_activity') }}
    </h1>
    <v-row class="px-3" :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col cols="6" sm="3">
        <BaseDatepickerInput
          v-model="startDate"
          :clearable="false"
          :label="$t('from')"
          @input="onStartDateChange"
        />
      </v-col>
      <v-col cols="6" sm="3">
        <BaseDatepickerInput
          v-model="endDate"
          :clearable="false"
          :label="$t('to')"
          @input="onEndDateChange"
        />
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          v-model="search"
          :label="$t('search_for_employee')"
          prepend-inner-icon="mdi-magnify"
          clearable
          hide-details
        />
      </v-col>
    </v-row>

    <v-progress-circular
      v-if="loading"
      class="mx-3"
      color="primary"
      size="50"
      width="4"
      indeterminate
    />

    <h5 v-else-if="noData" class="subtitle-2 grey--text px-3">
      {{ $t('no_data') }}
    </h5>

    <v-row v-else :no-gutters="$vuetify.breakpoint.xsOnly">
      <template v-for="date in dateRange">
        <v-col v-if="activityByDateMap[date]" :key="date" cols="12" md="6" lg="4">
          <v-simple-table class="mb-6 mb-sm-4" dense>
            <thead>
              <tr>
                <th>
                  {{ getWeekdayName(date) }} <span class="text-no-wrap">{{ date }}</span>
                </th>
                <th class="text-right">{{ $t('from') }}</th>
                <th class="text-right">{{ $t('to') }}</th>
                <th class="text-right">{{ $t('total') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity in activityByDateMap[date]" :key="activity.user.id">
                <td>{{ activity.user.person.full_name }}</td>
                <td class="text-right">{{ activity.start }}</td>
                <td class="text-right">{{ activity.end }}</td>
                <td class="text-right">{{ activity.diff }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </template>

      <v-col v-if="userActivity.length" cols="12" md="6" lg="4">
        <v-simple-table class="mb-6 mb-sm-4 font-weight-medium" dense>
          <thead>
            <tr>
              <th>
                {{ $t('whole_period') }}
              </th>
              <th class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>mdi-information</v-icon>
                  </template>
                  <span> {{ $t('worked_in_total_in_chosen_period') }}. </span>
                </v-tooltip>
              </th>
              <th class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>mdi-information</v-icon>
                  </template>
                  <span>
                    {{ $t('total_work_hour_count_in_chosen_period') }}<br />
                    {{ $t('8_hours_a_day_weekends_included') }}
                  </span>
                </v-tooltip>
              </th>
              <th class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>mdi-information</v-icon>
                  </template>
                  <span>
                    {{ $t('difference_between_hours_worked_and_total_work_hours') }}<br />
                    {{ $t('if_negative_hours_worked_exceeded_total_work_hours') }}
                  </span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredUsers" v-html="getFullWorkHours(user)" :key="user.id" />
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { addDays, differenceInMinutes, eachDayOfInterval, format, startOfWeek } from 'date-fns';
import { lt } from 'date-fns/locale';
import userService from '../api/user-service';
import BaseDatepickerInput from '../components/base/BaseDatepickerInput';
import { getNewDate } from '@/util/date';

export default {
  name: 'UserActivityReport',

  components: { BaseDatepickerInput },

  data() {
    return {
      search: '',
      startDate: '',
      endDate: '',
      dateRange: [],
      userActivity: [],
      users: [],
      maxMinutesInPeriod: 0,
      maxWorkLabel: 0,
      loading: false,
    };
  },

  computed: {
    filteredUsers() {
      if (!this.search) {
        return this.users;
      }
      const search = this.search.toLowerCase();
      return this.users.filter(
        u =>
          u.person.full_name
            .toLowerCase()
            .trim()
            .indexOf(search) > -1,
      );
    },

    filteredUserMap() {
      const map = {};
      this.filteredUsers.forEach(user => {
        map[user.id] = user;
      });
      return map;
    },

    activityByDateMap() {
      const map = {};

      this.userActivity.forEach(activity => {
        if (!this.filteredUserMap[activity.user.id]) {
          return;
        }

        const date = activity.created_at.slice(0, 10);
        const start = getNewDate(activity.created_at);
        const end = getNewDate(activity.updated_at);
        const minutes = differenceInMinutes(end, start);
        const diff = format(new Date(1970, 1, 1, 0, minutes), 'HH:mm');

        const row = {
          user: activity.user,
          start: format(start, 'HH:mm'),
          end: format(end, 'HH:mm'),
          diff,
        };

        if (!map[date]) {
          map[date] = [row];
        } else {
          map[date].push(row);
        }
      });

      return map;
    },

    noData() {
      return !Object.keys(this.activityByDateMap).length;
    },
  },

  created() {
    this.startDate = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
    this.endDate = format(addDays(new Date(this.startDate), 4), 'yyyy-MM-dd');
    this.getReport();
  },

  methods: {
    getReport() {
      this.loading = true;
      const params = {
        starts_at: this.startDate,
        ends_at: this.endDate,
      };
      userService
        .getUserActivityReport(params)
        .then(res => {
          this.userActivity = res.data.user_activity;
          this.dateRange = eachDayOfInterval({
            start: getNewDate(res.data.starts_at),
            end: getNewDate(res.data.ends_at),
          }).map(d => format(d, 'yyyy-MM-dd'));
          this.maxMinutesInPeriod = this.dateRange.length * 8 * 60;
          this.maxWorkLabel = this.getTimeLabel(this.maxMinutesInPeriod);
          this.users = res.data.users;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getFullWorkHours(user) {
      let wasUserActive = false;
      let totalMinutes = 0;
      for (let i = 0; i < this.userActivity.length; i++) {
        const wh = this.userActivity[i];
        if (user.id === wh.created_by) {
          const start = getNewDate(wh.created_at);
          const end = getNewDate(wh.updated_at);
          const minutes = differenceInMinutes(end, start);
          totalMinutes += minutes;
          wasUserActive = true;
        }
      }
      if (!wasUserActive) {
        return '';
      }
      return `
        <td>${user?.person?.full_name}</td>
        <td class="text-right">${this.getTimeLabel(totalMinutes)}</td>
        <td class="text-right">${this.maxWorkLabel}</td>
        <td  class="text-right ${this.maxMinutesInPeriod < totalMinutes && 'text-underline'}">
          ${this.getTimeLabel(this.maxMinutesInPeriod - totalMinutes)}
        </td>
      `;
    },

    getWeekdayName(date) {
      const weekday = format(new Date(date), 'cccc', {
        locale: lt,
      });
      return weekday.charAt(0).toUpperCase() + weekday.slice(1);
    },

    // returns HH:mm from minutes
    getTimeLabel(totalMinutes) {
      if (!totalMinutes) {
        return '--';
      }
      let timeLabel = '';
      if (totalMinutes < 0) {
        timeLabel += '-';
      }
      // eslint-disable-next-line no-param-reassign
      totalMinutes = Math.abs(totalMinutes);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      if (hours < 10) {
        timeLabel += '0';
      }
      timeLabel += `${hours}:`;
      if (minutes < 10) {
        timeLabel += '0';
      }
      timeLabel += minutes;
      return timeLabel;
    },

    async onStartDateChange(date) {
      await this.$nextTick(); // waiting for v-text-field to update it's internals
      let startDate = date;
      if (!startDate) {
        startDate = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
      }
      if (startDate >= this.endDate) {
        this.endDate = format(addDays(new Date(startDate), 4), 'yyyy-MM-dd');
      }
      this.startDate = startDate;
      this.getReport();
    },

    async onEndDateChange(date) {
      await this.$nextTick(); // waiting for v-text-field to update it's internals
      let endDate = date;
      if (!endDate) {
        endDate = format(addDays(new Date(this.startDate), 4), 'yyyy-MM-dd');
      }
      if (endDate <= this.startDate) {
        this.startDate = format(addDays(new Date(endDate), -4), 'yyyy-MM-dd');
      }
      this.endDate = endDate;
      this.getReport();
    },
  },
};
</script>

<style scoped></style>
